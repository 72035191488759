import React, {useState, useEffect, useCallback} from 'react';
import './sidebar.css'  
import Logo from '../../assets/icons/ikinghub_black.svg'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import home_icon from '../../assets/icons/home_icon.svg'
import home_icon_active from '../../assets/icons/home_icon_active.svg'
import trade_icon from '../../assets/icons/trade_icon.svg'
import trade_icon_active from '../../assets/icons/trade_icon_active.svg'
import notifications_icon from '../../assets/icons/notifications_icon.svg'
import notifications_icon_active from '../../assets/icons/notifications_icon_active.svg'
import account_icon from '../../assets/icons/account_icon.svg'
import account_icon_active from '../../assets/icons/account_icon_active.svg'
import setting_icon from '../../assets/icons/Settings_icon.svg'
import setting_icon_active from '../../assets/icons/Settings_icon_active.svg'
import profile_icon from '../../assets/icons/profile_icon.svg'
import profile_icon_active from '../../assets/icons/profile_icon_active.svg'
import bell_icon from '../../assets/icons/bell_icon.svg'
import user_icon from '../../assets/icons/user_icon.svg'
import logout_icon from '../../assets/icons/Logout.svg'
import usersicon from '../../assets/icons/users_icon.svg'
import usersicon_active from '../../assets/icons/users_iconActive.svg'
import users from '../../assets/icons/users.svg'
import users_active from '../../assets/icons/users_active.svg'
import creditcard from '../../assets/icons/credit-card.svg'
import creditcard_active from '../../assets/icons/credit-cardActive.svg'
import divide_icon from '../../assets/icons/divide.svg'
import divide_icon_active from '../../assets/icons/divide_active.svg'
import ReactTooltip from 'react-tooltip';
import { logOut } from '../../store/actions/auth';
import { connect } from 'react-redux';


const UserSidebar = (props) => {
    const [sideShow, setSideShow] = useState(false);
    
    const navigate = useNavigate()

    const {Logout,profile, userRole} = props

    
  // check for which path you are on
  const isActive = useLocation().pathname;

  useEffect(() => {
    document.body.classList.add("userBg");
  }, []);

    const ToggleSidebar = () => {
        setSideShow(sideShow ? false : true);
    };

    const ToggleLogout = () => {
      Logout();
      navigate('/')
    };
  
     const closeSideBar = useCallback(() => {
       setSideShow(sideShow ? false : true);
     }, [sideShow]);
    
    useEffect(() => {
    if (sideShow) {
        document.body.addEventListener("click", closeSideBar);

        return () => document.body.removeEventListener("click", closeSideBar);
    }
    }, [closeSideBar, sideShow]);
    
    return ( 
        <>
         <div className={sideShow ? "userside show" : "userside"}>
        {/* logo */}
        <div className="logo mt-4">
          <img src={Logo} alt="logo" />
        </div>
        {/* nav list */}
        <div className="v-list">
          <div>
            {/* first */}
            <Link
              to="/overview"
              className={
                isActive.includes("/overview")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/overview")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/overview") ? (
                  <img src={home_icon_active} alt="overview" />
                ) : (
                  <img src={home_icon} alt="overview" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/overview") ? "active" : ""}
                >
                  Overview
                </span>
              </div>
            </Link>

            <Link
              to="/trades"
              className={
                isActive.includes("/trades")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/trades")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/trade") ? (
                  <img src={trade_icon_active} alt="trades" />
                ) : (
                  <img src={trade_icon} alt="trades" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/trade") ? "active" : ""}
                >
                  Trades
                </span>
              </div>
            </Link>

            <Link
              to="/transactions"
              className={
                isActive.includes("/transaction")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/transaction")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/transaction") ? (
                  <img src={account_icon_active} alt="wallet" />
                ) : (
                  <img src={account_icon} alt="wallet" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/transaction") ? "active" : ""}
                >
                 Transactions
                </span>
              </div>
            </Link>

        
            {
              userRole === 'Admin' &&
            <Link
              to="/requests"
              className={
                isActive.includes("/request")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/request")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/request") ? (
                  <img src={creditcard_active} alt="notifications icon" />
                ) : (
                  <img src={creditcard} alt="notifications icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/request") ? "active" : ""}
                >
                  Withdrawal Requests 
                </span>
              </div>
            </Link>
            }

            <Link
              to="/users"
              className={
                isActive.includes("/user")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/user")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/user") ? (
                  <img src={users_active} alt="users icon" />
                ) : (
                  <img src={users} alt="account icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/user") ? "active" : ""}
                >
                  Users
                </span>
              </div>
            </Link>
          

            {
              userRole === 'Admin' &&
            <Link
              to="/admin"
              className={
                isActive.includes("/admin")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/admin")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/admin") ? (
                  <img src={profile_icon_active} alt="notifications icon" />
                ) : (
                  <img src={profile_icon} alt="account icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/admin") ? "active" : ""}
                >
                  Admin
                </span>
              </div>
            </Link>
            }

            <Link
              to="/giftcardrates"
              className={
                isActive.includes("/giftcard")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/giftcardrate")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/giftcardrate") ? (
                  <img src={notifications_icon_active} alt="notifications icon" />
                ) : (
                  <img src={notifications_icon} alt="account icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/giftcardrate") ? "active" : ""}
                >
                  Giftcard Rates
                </span>
              </div>
            </Link>

            <Link
              to="/cryptorates"
              className={
                isActive.includes("/cryptorate")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/cryptorate")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/cryptorate") ? (
                  <img src={divide_icon_active} alt="crypto icon" />
                ) : (
                  <img src={divide_icon} alt="crypto icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/cryptorate") ? "active" : ""}
                >
                  Crypto Rates
                </span>
              </div>
            </Link>

           {
              userRole === 'Admin' &&
            <Link
              to="/settings"
              className={
                isActive.includes("/settings")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/settings")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/settings") ? (
                  <img src={setting_icon_active} alt="security icon" />
                ) : (
                  <img src={setting_icon} alt="security icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/settings") ? "active" : ""}
                >
                  Settings
                </span>
              </div>
            </Link>
          }

            <Link
              to="/profile"
              className={
                isActive.includes("/profile")
                  ? "v-list-item v-list-link active"
                  : "v-list-item v-list-link"
              }
              style={{ textDecoration: "none" }}
            >
              {/* icon */}
              <div
                className={
                  isActive.includes("/profile")
                    ? "v-list-icon active"
                    : "v-list-icon"
                }
              >
                {isActive.includes("/profile") ? (
                  <img src={usersicon_active} alt="profile icon" />
                ) : (
                  <img src={usersicon} alt="profile icon" />
                )}
              </div>
              {/* title */}
              <div className="v-list-title">
                <span
                  className={isActive.includes("/profile") ? "active" : ""}
                >
                  Profile
                </span>
              </div>
            </Link>
          </div>


          {/* sidebar footer */}
          <div className='sidebar-footer-div'>
              <div className='sidebar-user-icon'>
                <img src={user_icon} alt="user" />
              </div>
              <div>
                <p className='mb-0 '>Hi, {profile && profile.hasOwnProperty("firstName") ? profile.firstName: "Nill"}</p>
              </div>
              <div className='sidebar-logout'
                 onClick={ToggleLogout}
              >
                <span data-tip data-for="logoutTip"><img src={logout_icon} alt="logout" /></span>
              </div>

              <ReactTooltip id="logoutTip" place="left" effect="solid">
                Logout
              </ReactTooltip>
          </div>

         
        </div>

       
      </div>

      {/* navbar */}
      <nav>
        <div className="sidehead">
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* icon for hamburger */}
            <div
              onClick={ToggleSidebar}
              className="d-md-none d-sm-block"
              style={{ marginRight: "12px", cursor: "pointer" }}
            >
              <i className="mdi mdi-menu" style={{ fontSize: 24, color:"#F7931A" }}></i>
            </div>

            {/* back arrow */}
            <div>
              {props.children}  
            </div>  

           
            <div style={{ flexGrow: 1 }}></div>
              <div>
                <div>
                  <div>
                    <img
                      className="imageStyle"
                      src={bell_icon}
                      alt="user"
                    />
                  </div>
                </div>
              </div>
           
          </div>
        </div>
      </nav>

        </>
     );
}
 

const mapStateToProps = (state) =>{
  return{
      profile: state.auth.profile,
      userRole: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
      Logout: () => dispatch(logOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSidebar);