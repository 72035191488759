import cogoToast from "cogo-toast";
import { GetApi, PostApi, DeleteApi, PutApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// get all giftcards subcategories
export const getSubCategory = (val) => {
    return async (dispatch, getState) => {
        dispatch({ type: "Loader"})
      try {
        const res = await GetApi("ratecalculator/giftcard/subcategory/listing", getToken());
        if (res.status === 200) {
          dispatch({ type: "SubCategories", data: res.data.message});
        }
        if(res.status === 400){
          dispatch({ type: "Subcategory_Error", err: res.data });
        }
      } catch (err) {
       console.log(err)
      }
    };
};

// get all categories
export const getCategory = (val) => {
    return async (dispatch, getState) => {
        dispatch({ type: "Loader"})
      try {
        const res = await GetApi("ratecalculator/giftcard/category/listing", 'iking2022');
        if (res.status === 200) {
          dispatch({ type: "Categories", data: res.data.message});
        }
        if(res.status === 400){
          dispatch({ type: "Category_Error", err: res.data });
        }
      } catch (err) {
       console.log(err)
      }
    };
};

// create a new subcategory of giftcard
export const CreateSubCategory = (val) => {
    return async (dispatch, getState) => {
      try {
        const res = await PostApi(
          "ratecalculator/giftcard/category/subcategory",
          {
            categoryname: val.categoryname,
            categoryId: val.categoryId,
            subcategoryname: val.subcategoryname,
            termsandconditions: val.termsandconditions,
            nairarate: val.nairarate,
            btcrate: 0,
            cardapproveltime: 0,
            minimumAmount: val.minimumAmount,
            maximumAmount: val.maximumAmount
          },
          getToken(),
          "application/json"
        );
        if (res.status === 201) {
          dispatch({ type: "Rate_Success" });
          cogoToast.success("Giftcard added successfully!");
        }
        if(res.status === 400){
          dispatch({ type: "Rate_Fail", err: res.data});
          cogoToast.error('Giftcard already exist!')
        }
      } catch (err) {
        console.log(err)
      }
    };
  };


  // update gitfcard subcategory
  export const UpdateSubCategory = (val) => {
    return async (dispatch, getState) => {
      try {
        const res = await PutApi(
          `ratecalculator/giftcard/subcategory/listing/${val.id}`,
          {
            categoryname: val.categoryname,
            categoryId: val.categoryId,
            subcategoryname: val.subcategoryname,
            termsandconditions: val.termsandconditions,
            nairarate: val.nairarate,
            btcrate: 0,
            cardapproveltime: 0,
            minimumAmount: val.minimumAmount,
            maximumAmount: val.maximumAmount
          },
          getToken(),
          "application/json"
        );
        if (res.status === 200) {
          dispatch({ type: "Rate_Success" });
          cogoToast.success("Giftcard updated successfully!");
        }
        if(res.status === 400){
          dispatch({ type: "Rate_Fail", err: res.data});
          cogoToast.error('Giftcard already exist!')
        }
      } catch (err) {
        console.log(err)
      }
    };
  };


  // delete a giftcard category
export const deleteGiftCards = (id, categoryId) => {
    return async (dispatch, getState) => {
      try {
        const res = await DeleteApi(`ratecalculator/giftcard/subcategory/listing/${id}?categoryId=${categoryId}`, getToken());
        if (res.status === 200) {
          dispatch({ type: "GiftCards"});
          cogoToast.success('Giftcard deleted successfully!')
        }
        if(res.status === 400){
          dispatch({ type: "Card_Error", err: res.data });
        }
      } catch (err) {
       console.log(err)
      }
    };
  };
  




