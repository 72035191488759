import React,{useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './dashboard.module.css'
import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import DoughnutChart from '../../../components/Charts/Doughnut';
import { connect } from 'react-redux';
import { getAllRequestWithLimit } from '../../../store/actions/requests';
import Moment from "react-moment";
import { getDashboardAnalytics } from '../../../store/actions/dashboard';
import CountUp from "react-countup";

const AdminDashboard = ({requests, fetchRequest, loading, fetchOverviewData, totalOutflow, pendingWithdrawerRequest, allUsersCount, completedTrade}) => {

    const navigate = useNavigate()

    useEffect(()=>{
      fetchRequest()
      fetchOverviewData()
    },[fetchRequest, fetchOverviewData])

    const columns = [
        {
          name: "Name",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`${row.userDetails.firstName} ${row.userDetails.lastName}`}</span>
          ),
        },
        {
          name: "Amount",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`NGN ${row.amount}`}</span>
          ),
        },
        {
          name: "Date Requested",
          cell: (row) => (
            <span className={styles.recent_table_row}>
             <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
            </span>
          ),
        },
        {
          name: "Status",
          cell: (row) => (
            <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
          ),
        },
        {
          name: "Action",
          button: true,
          cell: (row) =>  (
            <button
            className="btn btn-sm btn-view"
            onClick={() => {
              ViewRequest(row._id);
            }}
          >
            View
          </button>
          ),
        },
      ];


      const ViewRequest = (id) =>{
        navigate(`/request/${id}`)
      }

      
      const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result = "pending_icon";
            break;
          case "Completed":
            result = "completed_icon";
            break;
          case "Declined":
            result = "failed_icon";
            break;
          default:
            break;
        }
        return result;
      };

    

    return ( 
        <>
         <UserSidebar>
            <div>
                <p className="titlehead">Overview</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className='row mt-5'>
                    <div className='col-lg-3 mb-lg-0 mb-4'>
                        <div className={styles.dash_div}>
                            <div className='mt-1'>
                                    <h6>Total Outflow (NGN)</h6>
                            </div>

                            <div className='mt-3'>
                                <h4>
                                <CountUp
                                    start={0}
                                    end={totalOutflow ? totalOutflow : 0}
                                    duration={2.5}
                                    separator=","
                                  />
                                  </h4>
                            </div>

                        </div>
                    </div>

                    <div className='col-lg-3 mb-lg-0 mb-4'>
                        <div className={styles.dash_div}>
                            <div className='mt-1'>
                                    <h6>Completed Trades</h6>
                            </div>

                            <div className='mt-3'>
                                <h4>{completedTrade}</h4>
                            </div>

                        </div>
                    </div>

                    <div className='col-lg-3 mb-lg-0 mb-4'>
                        <div className={styles.dash_div}>
                            <div className='mt-1'>
                                    <h6>Pending Request(s)</h6>
                            </div>

                            <div className='mt-3'>
                                <h4>{pendingWithdrawerRequest}</h4>
                            </div>

                        </div>
                    </div>

                    <div className='col-lg-3'>
                        <div className={styles.dash_div}>
                            <div className='mt-1'>
                                    <h6>Users</h6>
                            </div>

                            <div className='mt-3'>
                                <h4>{allUsersCount}</h4>
                            </div>

                        </div>
                    </div>

                </div>

                <div className='row mt-5'>
                    <div className='col-lg-8'>

                      <div className={styles.dash_request_container}>
                            <DataTable
                                title="Recent Request(s)"
                                pagination
                                persistTableHead
                                columns={columns}
                                data={requests}
                                progressPending={loading}
                            />
                        </div>


                    </div>

                    <div className='col-lg-4'>

                        <div className={`${styles.analytics_div}`}>
                            <h6 className={styles.analytics_title}>Analytics</h6>
                            <DoughnutChart />
                        </div>

                    </div>
                </div>

            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
  return {
      requests: state.request.requests,
      loading: state.request.loading,
      totalOutflow: state.dashboard.totalOutflow,
      pendingWithdrawerRequest: state.dashboard.pendingWithdrawerRequest,
      completedTrade: state.dashboard.completedTrade,
      allUsersCount: state.dashboard.allUsersCount,
      
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRequest: () => dispatch(getAllRequestWithLimit()),
    fetchOverviewData: () => dispatch(getDashboardAnalytics()),
  };
}
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);